import { css } from '@emotion/react';
import { theme } from './layout/styles/GlobalStyles';

export const miniCardStyles = css`
    max-width: 328px;

    > h4 {
        margin: 16px 0 0;
        text-transform: capitalize;
        transition: color 0.3s ease-in-out;
    }

    > p {
        margin: 16px 0 0;
    }

    img {
        transition: transform 0.3s ease-in-out, opacity 500ms linear !important;
    }

    :hover,
    :focus {
        > h4 {
            color: ${theme.colors.green2};
        }

        img {
            transform: scale(1.1);
        }
    }

    @media (min-width: 1024px) {
        > h4 {
            margin-top: 28px;
        }

        > p {
            margin: 20px 0 0;
        }
    }

    @media (min-width: 1280px) {
        > h4 {
            margin-top: 40px;
        }

        > p {
            margin: 24px 0 0;
        }
    }
`;
