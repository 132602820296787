import { CommonHero } from '@/CommonHero';
import { flexCenter, px } from '@/layout/styles/classes';
import { miniCardStyles } from '@/MiniCard';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { Fragment } from 'react';

const Article = styled.article`
    ${px};
    ${flexCenter};
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 1190px;
    gap: 24px;
    box-sizing: content-box;
    padding-top: 64px;
    padding-bottom: 64px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 1280px) {
        gap: 40px;
    }
`;

const Card = styled.div`
    ${miniCardStyles};
    max-width: 370px;

    > h4 {
        margin-top: 16px;
        font-size: 1.25rem;
    }
`;

const text = [
    {
        title: 'PRP Hair',
    },
    {
        title: 'Restylane Kysse',
    },
    {
        title: 'Scarlet SRF Micro Needling',
    },
    {
        title: 'Radiesse Hands Injections for Volume',
        text: "Radiesse is used to rejuvenate the patient's hands by adding volume to the dorsal side of hands",
    },

    {
        title: 'Dramatic Juvederm Lip Enhancement',
        text: 'This patient likes her lips to have a more dramatic look.  It looks stunning on her.  Not for everyone though...',
    },
    {
        title: 'Beautiful result of Juvederm Lips',
        text: 'Stunning and Natural-looking Lip Enhancement',
    },
    {
        title: 'PDO Thread Lifts Under Chin',
        text: 'PDO Threads are wonderful at firming and tightening loose skin under chin',
    },
    {
        title: 'Juvederm Lip Enhancement',
        text: 'Uneven volume corrected by Juvederm',
    },
    {
        title: 'Skin Tag Removal with EC',
        text: 'Tiny electrical needle is very helpful in removal of unsightly skin tags around neck',
    },
    {
        title: 'Juvederm Lip Injections',
        text: 'Juvederm can and should look natural in your lips. If you desire more dramatic change then additional volume will be needed to achieve the goal',
    },
    {
        title: 'Juvederm Lip Injections',
        text: 'Juvederm can help to achieve balance between upper and lower lip',
    },
    {
        title: 'Juvederm Lip Injections',
        text: 'Juvederm lifted upper lip by adding volume',
    },
    {
        title: 'PRP With Microneedling Miracle',
        text: 'Miraculous transformation of skin texture after 5 PRP Microneedling treatments',
    },
    {
        title: 'Juvederm Lips Enhancement',
        text: 'This patient has full lips, to begin with. We add volume for a more voluptuous look she likes',
    },
    {
        title: 'Radiesse for Hands Volume',
        text: 'Radiesse injections to replenish lost volume in hands. Makes skin look plumper and your hands look younger.',
    },
    {
        title: 'Juvederm Lip Enhancement',
        text: 'Very natural look after first time Juvederm Injections. Injecting more volume will create more dramatic result.',
    },
    {
        title: 'Juvederm Lip Injections',
        text: 'Uneven volume can be corrected by Juvederm injections to create more balanced look',
    },
    {
        title: 'Botox for Neck',
        text: 'Botox for the neck to improve the appearance of neck skin, and reduce sagging',
    },
    {
        title: 'PDO Thread Lift',
        text: 'PDO Threads are wonderful at firming and tightening skin',
    },
    {
        title: 'Liquid Face Lift',
        text: 'An aging face that starts sagging and descending may be lifted by adding volume to the right places so you look natural and not overdone.',
    },
    {
        title: 'Juvederm Lips enhancement',
        text: 'Natural looking Juvederm Lip Enhancement',
    },
    {
        title: 'Radiesse for Hands Volume',
        text: 'Radiesse injections to replenish lost volume in hands. Makes skin look plumper and your hands look younger.',
    },
    {
        title: 'Sculptra for Skin Texture',
        text: 'skin elasticity, fine lines, laxity improvement post Sculptra',
    },
    {
        title: 'Juvederm Lips Enhancement',
        text: 'Juvederm Injections should look natural and proportional to the individual face.',
    },
    {
        title: 'Juvederm Lips Enhancement',
        text: 'This patient has full lips, to begin with. We add volume for a more voluptuous look she likes',
    },
    {
        title: 'Botox Injections',
        text: 'After Botox injections tired-looking skin turns into fresh, tight, and young-looking skin (like a "school girl forehead" after!)',
    },
    {
        title: 'FotoFacial for Brown Spot on Face',
        text: 'sun spot removal with FotoFacial IPL',
    },
    {
        title: 'FotoFacial For Sun Damage on Hands',
        text: 'Fotofacial rejuvenates and reduces number of brown spots/ sun damaged spots on hands.',
    },
    {
        title: 'Botox Relaxation of Frown',
        text: 'Very strong frown can be relaxed in a natural looking way.',
    },
    {
        title: 'Under Eye Bags - Juvederm',
        text: 'Juvederm Injections can mask under-eye fat pads and create more smooth appearance to lower eyelids',
    },
    {
        title: 'Juvederm Enhancement',
        text: "It's so easy to make lips stunning when they are already beautiful and have a nice shape",
    },
    {
        title: 'FotoFacial to Sun Damaged Hands',
        text: 'Fotofacial reduces the number of brown spots / sun-damaged spots on hands.',
    },
    {
        title: 'PDO Thread Lift Under Chin',
        text: 'Loose skin under chin may be improved and firmed by PDO Threads inserted intradermally',
    },
    {
        title: 'Radiesse Injection for Hands Volume',
        text: 'Our hands often give away our age by losing volume under the skin. Radiesse can correct volume depletion',
    },
    {
        title: 'Juvederm Correction of Under Eye Circle ',
        text: 'Juvederm is used to correct and smooth depressions under eyes/ tear trough correction',
    },
    {
        title: 'Radiesse Hands Volume',
        text: 'Correcting volume depletion in hands creates more youthful and rejuvenated look of hands.',
    },
    {
        title: 'PRP with Microneedling Miracle',
        text: 'Loose and tired looking skin of the lower eyelids regenerated into tight and young-looking skin after PRP with microneedling',
    },
    {
        title: 'Sculptra',
        text: 'Sculptra improves skin firmness and wrinkles without adding unnecessary volume or "puffiness" to certain areas on face.',
    },
    {
        title: 'FotoFacial IPL Treatment',
        text: 'This Patient is getting FotoFacial IPL Skin Rejuvenation',
    },
    {
        title: 'Voluma Correcting Mid Face Volume',
        text: 'Correct Volume Loss to Midface and enhance cheek bones with Voluma or Radiesse',
    },
    {
        title: 'Juvederm Lip Injections',
        text: 'Juvederm can help to achieve balance between upper and lower lip',
    },
    {
        title: 'Under Eye Juvederm',
        text: 'Fill in depressions/ circles under eyes with Juvederm',
    },
    {
        title: 'Tear Trough Juvederm Injections',
        text: 'Bags under eyes that produce a depressed circle may be improved by careful placement of Juvederm in this area.',
    },
    {
        title: 'Juvederm Lip Injections',
        text: 'Juvederm is great to balance upper and lower lip. This patient complained about upper lip protrusion (overbite). Adding volume to lower lip solved this problem.',
    },
    {
        title: 'Juvederm Lips enhancement Injections',
        text: 'Subtle Juvederm Lips enhancement',
    },
    {
        title: 'PDO Thread Lift Under Chin',
        text: 'PDO Threads are wonderful at firming and tightening loose skin under chin',
    },
    {
        title: 'Tear Trough Juvederm Injections',
        text: 'Circles under eyes make us look tired and aged. Juvederm can greatly reduce them and add freshness to face',
    },
    {
        title: 'FotoFacial For Pigmented Sun Spots',
        text: 'Fotofacial reduces number of brown spots/ sun damaged spots on hands for more younger looking hands.',
    },
    {
        title: 'Botox',
    },
    {
        title: 'Botox',
    },
    {
        title: 'Carboxytherapy for Stretch Marks',
    },
    {
        title: 'Carboxytherapy for Stretch Marks',
    },
    {
        title: 'Fillers to correct jaw line',
    },
    {
        title: 'lip fillers',
    },
    {
        title: 'lip fillers',
    },
    {
        title: 'lip fillers',
    },
    {
        title: 'lip fillers',
    },
    {
        title: 'lip fillers',
    },
    {
        title: 'Plasma Fibroblast Treatment',
    },
    {
        title: 'Plasma Fibroblast Treatment',
    },
    {
        title: 'Plasma Fibroblast Treatment',
    },
    {
        title: 'Erbium',
    },
    {
        title: 'Hyaluronidase',
    },
    {
        title: 'Restylane Kysse',
    },
    {
        title: 'Restylane Kysse',
    },
    {
        title: 'Renuva',
    },
    {
        title: 'Lip Filler',
    },
    {
        title: 'Carboxytherapy',
    },
    {
        title: 'Botox',
    },
    {
        title: 'Kybella',
    },
    {
        title: 'Fotofacial IPL Treatment',
    },
    {
        title: 'Laser Hair Removal',
    },
    {
        title: 'Kybella',
    },
    {
        title: 'Lip Filler',
    },
    {
        title: 'Restylane Kysse',
    },
    {
        title: 'Restylane Kysse',
    },
    {
        title: 'Fotofacial',
    },
];

const Gallery: Page = () => {
    type DataType = {
        allFile: {
            nodes: ImageDataLike[];
        };
    };

    const images: DataType = useStaticQuery(graphql`
        query beforeAfterPageImages {
            allFile(
                filter: { relativeDirectory: { eq: "photo-gallery" } }
                sort: { order: ASC, fields: name }
                skip: 1
            ) {
                nodes {
                    childImageSharp {
                        gatsbyImageData(width: 370, height: 370)
                    }
                }
            }
        }
    `);

    const data = images.allFile.nodes.map((img, i) => ({
        image: getImage(img) as IGatsbyImageData,
        ...text[i],
    }));

    return (
        <Fragment>
            <CommonHero />
            <Article>
                {data.map((info, i) => (
                    <Card key={i}>
                        <GatsbyImage image={info.image} alt={info.title} />
                        <h4>{info.title}</h4>
                        {/* {info.text ? <p>{info.text}</p> : ''} */}
                    </Card>
                ))}
            </Article>
        </Fragment>
    );
};
export default Gallery;
